import React, { memo } from "react";
import s from "./LandingPage.module.scss";
import IntroBanner from "../../../components/IntroBanner";
import AnimatedBanner from "../../../components/AnimatedBanner";

const DesktopLandingPage = ({ userType, onSelectUserType, onSelectMode }) => {
  return (
    <div className={s.container}>
      <IntroBanner
        userType={userType}
        handleOnSelectUser={onSelectUserType}
        handleNavigateTo={onSelectMode}
      />
      <div className={s.banner}>
      <AnimatedBanner userType={userType} />
      </div>
      
    </div>
  );
};

export default memo(DesktopLandingPage);
