export const HOME_HEADER = {
  header: "Welcome to\nplanet now!",
  subHeader: "Discover the latest events and activities in your area with ease. Search by mood and budget to find what you'll love."
}

export const USER_HEADER = {
  header: "SOUNDS LIKE\nA PLAN!",
  subHeader: "Map-based mobile app for finding and sharing events with customisable filters."
}

export const BUSINESS_HEADER = {
  header: "Connect with Thousands of Event-Goers",
  subHeader: "Reach thousands of people actively searching for new events and activities."
}