import React, { memo } from "react";
import AnimatedMsgBubble from "../../AnimatedMsgBubble";
import s from "./BusinessFooterBubble.module.scss";

const BusinessFooterBubble = () => {
  return (
    <div className={s.container}>
      <AnimatedMsgBubble
        className={s.club}
        label="Club Event"
        count="1.4k followers"
        percent="+10%"
        delay={12000}
        showDuration={8000}
        hideDuration={6000}
      />
      <AnimatedMsgBubble
        className={s.tourism}
        label="Tourism Event"
        count="33 attendees"
        percent="+8%"
        delay={10000}
        showDuration={8000}
        hideDuration={6000}
      />
      <AnimatedMsgBubble
        className={s.couple}
        label="Couples Event"
        count="86 attendees"
        percent="+38%"
        delay={3000}
        showDuration={8000}
        hideDuration={6000}
      />
      <AnimatedMsgBubble
        className={s.food}
        label="Dinner Special"
        count="305 followers"
        percent="+25%"
        delay={0}
        showDuration={8000}
        hideDuration={6000}
      />
      <AnimatedMsgBubble
        className={s.sports}
        label="Sporting Event"
        count="1.8k attendees"
        percent="+14%"
        delay={6000}
        showDuration={8000}
        hideDuration={6000}
      />
      <AnimatedMsgBubble
        className={s.comedy}
        label="Comedy Event"
        count="120 attendees"
        percent="+2%"
        delay={8000}
        showDuration={8000}
        hideDuration={6000}
      />
      <AnimatedMsgBubble
        className={s.family}
        label="Family Event"
        count="240 followers"
        percent="+15%"
        delay={4500}
        showDuration={8000}
        hideDuration={6000}
      />
    </div>
  );
};

export default memo(BusinessFooterBubble);
