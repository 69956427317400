import React, { memo } from "react";
import {useWindowSize} from "react-use"
import Button from "../Button";
import s from "./ComingSoon.module.scss";

const ComingSoon = () => {
  const {width} = useWindowSize()
  const isMobile = width < 1200
  const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={s.container}>
      <div className={s.image}>
        <img alt="coming-soon" src="/coming-soon.png" />
      </div>
      <div className={s.details}>
        <div className={s.header}>{isMobile ? "Coming to your mobile soon" : "Coming to your desktop soon"}</div>
        <div className={s.subHeader}>
          For Businesses Please Use A PC or Laptop
        </div>
        <div className={s.link} onClick={() => openNewTab("https://www.planetnow.io")}>
          www.planetnow.io
        </div>
        <div className={s.actionBtns}>
          <Button
            className={s.button}
            Icon={<img alt="apple" src="/assets/img/apple-icon.svg" />}
            type="light"
            label="App Store"
          />
          <Button
            className={s.button}
            Icon={<img alt="google-play" src="google-play.png" />}
            type="light"
            label="Google Play"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ComingSoon);
