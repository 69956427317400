import React, { memo, useState, useEffect } from "react";
import cx from "classnames";
import s from "./AnimatedMsgBubble.module.scss";

const AnimatedMsgBubble = ({
  className,
  icon,
  title,
  label,
  count,
  percent,
  showDuration,
  hideDuration,
  delay,
}) => {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [showBody, setShowBody] = useState(false);

  useEffect(() => {
    if (showDuration)
      setTimeout(() => {
        setShow(true);
      }, delay);
  }, [delay, showDuration]);

  useEffect(() => {
    if (show && showDuration) {
      setHide(false);
      setTimeout(() => {
        setShowBody(true);
      }, 400);

      setTimeout(() => {
        setHide(true);
      }, [showDuration]);
    }
  }, [show, showDuration]);

  useEffect(() => {
    if (hide && hideDuration) {
      setTimeout(() => {
        setShow(false);
        setShowBody(false);
      }, [1000]);
      setTimeout(() => {
        setShow(true);
      }, [hideDuration]);
    }
  }, [hide, hideDuration]);

  return (
    <div
      className={cx(s.container, className, {
        [s.show]: show,
        [s.hide]: hide,
      })}
    >
      <div className={s.body}>
        <div className={s.col}>
          {icon && (
            <div class={s.icon}>
              <img src={icon} alt={`msg-bubble-${label}`} />
            </div>
          )}
        </div>
        <div className={cx(s.col, s.mid, { [s.show]: showBody })}>
          {title && (
            <div className={s.title}>
              <span>{title}</span>
            </div>
          )}
          {label && (
            <div className={s.label}>
              <span>{label}</span>
            </div>
          )}
          {count && (
            <div className={s.count}>
              <span>{count}</span>
            </div>
          )}
        </div>
        <div className={s.col}>
          {percent && (
            <div className={s.percent}>
              <span>{percent}</span>
            </div>
          )}
        </div>
      </div>
      <div className={s.arrow} />
      <div className={s.waveContainer}>
        <div className={s.wave} />
      </div>
    </div>
  );
};

export default memo(AnimatedMsgBubble);
