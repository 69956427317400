import React, { memo, useMemo } from "react";
import s from "./LandingPage.module.scss";
import AnimatedBanner from "../../../components/AnimatedBanner";
import UserSelect from "../../../components/UserSelect";
import LoginSignupSelect from "../../../components/LoginSignupSelect";
import {
  HOME_HEADER,
  USER_HEADER,
  BUSINESS_HEADER,
} from "../../../pages/Home/config";

const MobileLandingPage = ({ userType, onSelectUserType, onSelectMode }) => {
  const ActionBtns = useMemo(() => {
    switch (userType) {
      case 0:
        return <UserSelect handleOnSelect={onSelectUserType} />;
      case 2:
        return (
          <LoginSignupSelect
            onClick={onSelectMode}
            isBusiness={userType === 2}
          />
        );
      default:
        return <div className={s.label}>Coming Soon...</div>;
    }
  }, [userType, onSelectUserType, onSelectMode]);

  const HEADER = useMemo(() => {
    switch (userType) {
      case 1:
        return USER_HEADER;
      case 2:
        return BUSINESS_HEADER;
      default:
        return HOME_HEADER;
    }
  }, [userType]);

  return (
    <div className={s.container}>
      <div className={s.header}>{HEADER.header}</div>
      <div className={s.banner}>
        <AnimatedBanner userType={userType} />
      </div>
      <div className={s.subHeader}>{HEADER.subHeader}</div>
      {ActionBtns}
    </div>
  );
};

export default memo(MobileLandingPage);
