import React, { memo } from "react";
import s from "./PlanetHeader.module.scss";

const PlanetHeader = ({ onClick }) => {
  return (
    <div className={s.container} onClick={onClick}>
      PLANET
    </div>
  );
};

export default memo(PlanetHeader);
