import React, { memo } from "react";
import {useWindowSize} from "react-use"
import Desktop from "./Desktop"
import Mobile from "./Mobile"

const LandingPage = (props) => {
  const {width} = useWindowSize()
  const isMobile = width < 1024


  if(isMobile) return <Mobile {...props}/>

  return <Desktop {...props}/>;
};

export default memo(LandingPage);
