import React, { memo } from "react";
import AnimatedMsgBubble from "../../AnimatedMsgBubble";
import s from "./UserFooterBubble.module.scss";

const UserFooterBubble = () => {
  return (
    <div className={s.container}>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.rate18}
          icon="/footer/rated18.svg"
          title="Club Event"
          label="Over 18+"
          delay={12000}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.food}
          icon="/footer/food.svg"
          title="Dinner Special"
          label="Hospitality"
          delay={10000}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.couple}
          icon="/footer/couple.svg"
          title="Couples Event"
          label="Dating"
          delay={3000}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.comedy}
          icon="/footer/comedy.svg"
          title="Comedy Event"
          label="Entertainment"
          delay={0}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.sports}
          icon="/footer/sports.svg"
          title="Sporting Event"
          label="Sports"
          delay={6000}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.family}
          icon="/footer/family.svg"
          title="Family Event"
          label="Family"
          delay={8000}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
      <div className={s.plot}>
        <AnimatedMsgBubble
          className={s.tourism}
          icon="/footer/tourism.svg"
          title="Tourism Event"
          label="Tourism"
          delay={4500}
          showDuration={8000}
          hideDuration={6000}
        />
      </div>
    </div>
  );
};

export default memo(UserFooterBubble);
