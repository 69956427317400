import React, { useEffect, useState } from "react";
import BusinessAppLayout from "../../../layout/components/BusinessAppLayout";
import CircularProgress from "@mui/joy/CircularProgress";
import { Percent } from "@mui/icons-material";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import BusinessAdminLayout from "../layout/BusinessAdminLayout";
import ProgressCircle from "../components/ProgressCircle";
import ProgressLinear from "../components/ProgressLinear";
import StatisticsComponent from "../components/StatisticsComponent";
import UpcomingPlaceholder from "../components/UpcomingPlaceholder";
import businessEventServices from "../businessEventServices";
import { useAuth } from "../../../context/AuthContext";
import { Skeleton } from "@mui/material";
import moment from 'moment'

import {LinearProgress} from "@mui/material";




function BusinessStatisticsPage() {

    const authUser = useAuth();
    //const [ongoingEvents,setOngoingEvents] = useState(null)
    //const [upcommingEvents,setUpcommingEvents] = useState(null)
    const [allEvents,setAllEvents] = useState(null)
    const [loading,setLoadingStatus] = useState(false);
    
    
    /*
    let { businessFeedData } = useBusinessFeedData();
    useEffect(()=>{
        console.log(businessFeedData)
        setOngoingEvents(businessFeedData.event_list.ongoing)
    },[businessFeedData])
    */



    const getEventListWithStas = () =>{
        setLoadingStatus(true);
        let payload = {
            user_token : authUser.authUser.user_token,
            utc_date_time : moment().format("YYYY-MM-DD HH:mm:ss")
        }
        businessEventServices.getEventListWithStats(payload).then(res=>{
            //setOngoingEvents(res.data.event_list.ongoing)
            //setUpcommingEvents(res.data.event_list.upcomming)
            let _allEvents = []
            _allEvents.push(...res.data.event_list.ongoing);
            _allEvents.push(...res.data.event_list.upcomming);
            setAllEvents(_allEvents)

        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getEventListWithStas()
    },[])

    return (
    <BusinessAdminLayout>
        <div className="top-heading-row">
            <h1 class="web-page-heading">Statistics</h1>
        </div>
        {!loading ?
            <>
                {allEvents && allEvents.length > 0 && 
                    <>
                        {allEvents.map(eventDetails=><StatisticsComponent eventDetails={eventDetails} />)}
                        
                    </>
                }
                {allEvents == null && 
                    <UpcomingPlaceholder/>
                }
            </>
            :
            <div className="mt-4 text-center">
                <LinearProgress />
            </div>
        }

        
        
        
    </BusinessAdminLayout>
  );
}

export default BusinessStatisticsPage;
