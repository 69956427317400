import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import stripeServices from "../stripeServices";
import { useParams } from "react-router";
import { useAuth } from "../../../context/AuthContext";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";

const stripePromise = loadStripe(
  "pk_test_51PVmiz2MFykeC0YXwyPAeXSzTsS1sVIPGQWvquWl9Xn6YoOi2P1guCHuvf5QYrCay4NBAwBxtuJtOTmXW8cBc2i600ExZIO0Ya"
);

function StripeCheckoutComponent() {
  const { authUser } = useAuth();
  const { businessFeedData, getBusinessFeedData } = useBusinessFeedData();

  const [options, setOptions] = useState(null);
  const { packageId } = useParams();

  const doCheckout = () => {
    stripeServices
      .initiateCheckout({
        user_token: authUser.user_token,
        packageId: packageId,
        requestOrigin: window.origin,
      })
      .then((res) => {
        console.log(res);
        setOptions({ clientSecret: res.clientSecret });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    doCheckout();
  }, []);

  return (
    <div className="card">
      <div className="card-body px-4">
        {/* <h1
          className={`card-title mt-4 ${
            businessFeedData?.business_profile.is_subscribed == 0
              ? "signup-heading"
              : "update-plan-heading"
          }`}
        >
          {businessFeedData?.business_profile.is_subscribed == 0
            ? "SIGN UP"
            : "UPGRADE NOW"}
        </h1> */}
        <h1
          className={`card-title mt-4 ${
            businessFeedData?.business_profile.is_subscribed == 0
              ? "signup-heading"
              : "update-plan-heading"
          }`}
        >
          {businessFeedData?.business_profile.is_subscribed == 0
            ? "SIGN UP"
            : "UPGRADE NOW"}
        </h1>

        <p class="card-text">You’re On The Right Path.</p>
        {options && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
}

export default StripeCheckoutComponent;
