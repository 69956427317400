import React from 'react'
import { AlertProvider } from './planet/context/AlertContext';
import { useAuth } from './planet/context/AuthContext';
import { Route, Routes } from 'react-router'
// import AuthLandingPage from './planet/app/auth/pages/AuthLandingPage';
import LoginPage from './planet/app/auth/pages/LoginPage';
import BusinessRegisterPage from './planet/app/auth/pages/BusinessRegisterPage';
import UserRegisterPage from './planet/app/auth/pages/UserRegisterPage';
import BusinessDashboardPage from './planet/app/business/pages/BusinessDashboardPage';
import BusinessEventsPage from './planet/app/business/pages/BusinessEventsPage';
import BusinessStatisticsPage from './planet/app/business/pages/BusinessStatisticsPage';
import BusinessEventListViewPage from './planet/app/business/pages/BusinessEventListViewPage';
import BusinessProfilePage from './planet/app/business/pages/BusinessProfilePage';
import BusinessTemplatePage from './planet/app/business/pages/BusinessTemplatePage';
import LogoutPage from './planet/app/auth/pages/LogoutPage';
import ForgotPasswordPage from './planet/app/auth/pages/ForgotPasswordPage';
import BusinessEventDetailsPage from './planet/app/business/pages/BusinessEventDetailsPage';
import { BusinessFeedProvider } from './planet/context/BusinessFeedContext';
import BusinessTemplateFormPage from './planet/app/business/pages/BusinessTemplateFormPage';
import BusinessEventFormPage from './planet/app/business/pages/BusinessEventFormPage';
import BusinessTemplateDetailsPage from './planet/app/business/pages/BusinessTemplateDetailsPage';
import BusinessProfileFormPage from './planet/app/business/pages/BusinessProfileFormPage';
import UserDashboardPage from './planet/app/appuser/pages/UserDashboardPage';
import BusinessSupportPage from './planet/app/business/pages/BusinessSupportPage';
import BusinessOngoingEventsPage from './planet/app/business/pages/BusinessOngoingEventsPage';
import BusinessUpcomingEventsPage from './planet/app/business/pages/BusinessUpcomingEventsPage';
import BusinessProfilePlanPage from './planet/app/business/pages/BusinessProfilePlanPage';
import BusinessTemplateUseFormPage from './planet/app/business/pages/BusinessTemplateUseFormPage';
import BusinessCompanyProfilePage from './planet/app/business/pages/BusinessCompanyProfilePage';
import BusinessStripePaymentPage from './planet/app/business/pages/BusinessStripePaymentPage';
import BusinessStripeStatusPage from './planet/app/business/pages/BusinessStripeStatusPage';
import Home from "./planet/app/auth/pages/Home";





function App() {
    const {isLoggedIn,authUser} = useAuth()
    //console.log("authUSerrole", authUser)
    
    return (
        <>
        <AlertProvider>
            {isLoggedIn ?
                <>
                {authUser.roletype === "business" &&
                    <BusinessFeedProvider>
                        <Routes>
                            {/**  Business Module Page */}
                            <Route path="/" element={ <BusinessDashboardPage /> } />
                            <Route path="/business/support" element={ <BusinessSupportPage /> } />

                            <Route path="/business/events" element={ <BusinessEventsPage /> } />
                            <Route path="/business/events-list-view" element={ <BusinessEventListViewPage /> } />

                            <Route path="/business/events/ongoing" element={ <BusinessOngoingEventsPage /> } />
                            <Route path="/business/events/upcoming" element={ <BusinessUpcomingEventsPage /> } />

                            <Route path="/business/events/form" element={ <BusinessEventFormPage /> } />
                            <Route path="/business/events/form/:event_id" element={ <BusinessEventFormPage /> } />
                            <Route path="/business/events/details/:event_id" element={ <BusinessEventDetailsPage /> } />

                            

                            <Route path="/business/template" element={ <BusinessTemplatePage /> } />
                            <Route path="/business/template/form" element={ <BusinessTemplateFormPage /> } />
                            <Route path="/business/template/form/:template_id" element={ <BusinessTemplateFormPage /> } />
                            <Route path="/business/template/form/use/:template_id" element={ <BusinessTemplateUseFormPage /> } />
                            <Route path="/business/template/details/:template_id" element={ <BusinessTemplateDetailsPage /> } />


                            <Route path="/business/statistics" element={ <BusinessStatisticsPage /> } />
                            <Route path="/business/profile" element={ <BusinessProfilePage /> } />
                            <Route path="/business/company/profile" element={ <BusinessCompanyProfilePage /> } />
                            <Route path="/business/profile/plan" element={ <BusinessProfilePlanPage /> } />
                            <Route path="/business/profile/form" element={ <BusinessProfileFormPage /> } />

                            <Route path="/business/payment/:packageId" element={ <BusinessStripePaymentPage /> } />
                            <Route path="/business/payment/status/stripe" element={ <BusinessStripeStatusPage /> } />
                            

                            {/**  User Module Page  will be here*/}

                            {/* Commoen toutes for all ogged in user*/}
                            <Route path="/auth/logout" element={ <LogoutPage /> } />
                        </Routes>
                    </BusinessFeedProvider>
                }
                {authUser.roletype === "user" &&

                        <Routes>
                            {/**  Business Module Page */}
                            <Route path="/" element={ <UserDashboardPage /> } />
                            <Route path="/auth/logout" element={ <LogoutPage /> } />
                        </Routes>
                }
                </>
                :
                <Routes>
                    <Route path="/" element={ <Home /> } />
                    <Route path="/auth/login" element={ <LoginPage /> } />
                    <Route path="/auth/register/business" element={ <BusinessRegisterPage/> } />
                    <Route path="/auth/register/user" element={ <UserRegisterPage/> } />
                    <Route path="/auth/forgot-password" element={ <ForgotPasswordPage /> } />
                </Routes>
            }
        </AlertProvider>
        </>
    )
}
export default App