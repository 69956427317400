import React, { memo, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import s from "./Signup.module.scss";
import FormikControl from "../../../../../components/FormikControl/FormikControl";
import ImageInput from "../../../../../components/ImageInput/ImageInput";
import authServices from "../../../authServices";
import businessEventServices from "../../../../business/businessEventServices";
import { useAuth } from "../../../../../context/AuthContext";
import { useAlert } from "../../../../../context/AlertContext";
import Button from "../../../components/Button";
import GooglePlaceComponent from "../../../../business/components/GooglePlaceComponent";

const Signup = () => {
  const [eventTypes, setEventTypes] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const { AppAlert } = useAlert();
  const { setAuthUser, setIsLoggedIn } = useAuth();
  const [saving, setSavingStatus] = useState(false);
  const navigate = useNavigate();
  const [latLng, setLatLng] = useState(null);
  const [address, setAddress] = useState("");

  const initialValues = {
    business_name: "",
    email: "",
    password: "",
    mobile_country: "91",
    phone_no: "",
    mood: "",
    //address: "",
    is_business: 1,
    device_token: "Web",
  };

  const validationRule = Yup.object({
    business_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    password: Yup.string().required("Required"),
    mobile_country: Yup.string().required("Required"),
    phone_no: Yup.string().required("Required"),
    mood: Yup.string().required("Required"),
    //address: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    setSavingStatus(true);

    if (address === "") {
      AppAlert("Event Address Required", "error");
      setSavingStatus(false);
      return false;
    }
    if (latLng === null) {
      AppAlert("Please Select your location", "error");
      setSavingStatus(false);
      return false;
    }
    if (imageBase64 === null) {
      AppAlert("Logo Image is Required", "error");
      setSavingStatus(false);
      return false;
    }

    values.address = address;
    values.logo_image_base64 = imageBase64;
    values.latitude = latLng.lat;
    values.longitude = latLng.lng;

    setSavingStatus(true);
    authServices
      .registerBusiness(values)
      .then((res) => {
        //console.log(res);
        if (res.data.user !== undefined) {
          setAuthUser(res.data.user);
          setIsLoggedIn(true);
          navigate("/");
        }
      })
      .catch((error) => {
        AppAlert(error.message, "error");
        //console.log(error);
      })
      .finally(() => {
        setSavingStatus(false);
      });

    /*authServices.attemptLogin(values).then(res=>{
            console.log(res);
        }).catch(error=>{
            console.log(error);
        })
        */
  };

  const getEventTypes = () => {
    businessEventServices
      .getEventTypes()
      .then((res) => {
        setEventTypes(res.data.event_types);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEventTypes();
  }, []);

  return (
    <div className={s.container}>
      <div className={s.form}>
        <div className={s.header}>
          <span className={s.title}>SIGN UP</span>
          <span className={s.subHeader}>You’re on the right path.</span>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationRule}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnMount
        >
          {(formik) => {
            //console.log(formik);
            return (
              <Form>
                <div className={s.inputs}>
                  <div className="sign-up-profile">
                    <ImageInput
                      label={"Business Logo"}
                      value={null}
                      setImageBase64={setImageBase64}
                      type="circle"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-user-circle"></i>}
                      name="business_name"
                      placeHolder="Business Name"
                    />
                  </div>

                  {eventTypes && (
                    <div className="col">
                      <FormikControl
                        control="bsg-select"
                        formik={formik}
                        options={eventTypes}
                        valueField="event_type_id"
                        labelField="type_name"
                        label={
                          <img
                            src="/assets/img/icons/mood.svg"
                            alt="event-mood"
                          />
                        }
                        name="mood"
                        placeHolder="Email"
                      />
                    </div>
                  )}
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-envelope"></i>}
                      name="email"
                      placeHolder="Email"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="password"
                      label={<i class="bx icon bx-lock"></i>}
                      name="password"
                      placeHolder="Password"
                    />
                  </div>
                  <div className="col">
                    {address !== null && (
                      <GooglePlaceComponent
                        address={address}
                        setAddress={setAddress}
                        latLng={latLng}
                        setLatLng={setLatLng}
                      />
                    )}
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-phone"></i>}
                      name="phone_no"
                      placeHolder="Phone"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-globe"></i>}
                      name="website"
                      placeHolder="Website"
                    />
                  </div>
                  <div className="col">
                    <div className={s.actionBtns}>
                      <Button
                        type={formik.isValid && !saving ? "filled" : "disabled"}
                        isLoading={saving}
                        label="Next"
                        btnType="submit"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default memo(Signup);
