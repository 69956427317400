import React, { memo } from "react";
import { useNavigate } from "react-router";
import Button from "../Button";
import s from "./LoginSignupSelect.module.scss";

const LoginSignupSelect = ({ onClick, isBusiness }) => {
  const navigate = useNavigate();
  return (
    <div className={s.container}>
      <Button
        className={s.button}
        type="dark"
        label="login"
        handleClick={() => onClick(1)}
        isBusiness={isBusiness}
      />
      <Button
        className={s.button}
        type="light"
        label="signup"
        handleClick={() => onClick(2)}
        isBusiness={isBusiness}
      />
    </div>
  );
};

export default memo(LoginSignupSelect);
