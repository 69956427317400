import React, { memo, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import {
  HOME_HEADER,
  USER_HEADER,
  BUSINESS_HEADER,
} from "../../pages/Home/config";
import UserSelect from "../UserSelect";
import LoginSignupSelect from "../LoginSignupSelect";
import s from "./IntroBanner.module.scss";

const IntroBanner = ({ userType, handleOnSelectUser, handleNavigateTo }) => {
  const [isTransition, setIsTransition] = useState(false);

  useEffect(() => {
    setIsTransition(true);
    setTimeout(() => {
      setIsTransition(false);
    }, 500);
  }, [userType]);

  const HEADER = useMemo(() => {
    switch (userType) {
      case 1:
        return USER_HEADER;
      case 2:
        return BUSINESS_HEADER;
      default:
        return HOME_HEADER;
    }
  }, [userType]);

  const ActionBtns = useMemo(() => {
    switch (userType) {
      case 0:
        return <UserSelect handleOnSelect={handleOnSelectUser} />;
      case 2:
        return (
          <LoginSignupSelect
            onClick={handleNavigateTo}
            isBusiness={userType === 2}
          />
        );
      default:
        return <div className={s.label}>Coming Soon...</div>;
    }
  }, [userType, handleOnSelectUser, handleNavigateTo]);

  return (
    <div className={cx(s.container, { [s.show]: !isTransition })}>
      <div className={s.header}>{HEADER.header}</div>
      <div className={s.subHeader}>{HEADER.subHeader}</div>
      {ActionBtns}
    </div>
  );
};

export default memo(IntroBanner);
