import React, { memo } from "react";
import UserFooterBubble from "./UserFooterBubble";
import BusinessFooterBubble from "./BusinessFooterBubble";
import s from "./FooterMap.module.scss";

const FooterMap = ({ userType }) => {
  return (
    <div className={s.container}>
      <div className={s.footerContainer}>
        <img className={s.map} alt="logo" src="/footer/footer.png" />
      </div>
      <div className={s.bubbleContainer}>
        {userType === 2 ? <BusinessFooterBubble /> : <UserFooterBubble />}
      </div>
      
    </div>
  );
};

export default memo(FooterMap);
