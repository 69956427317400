import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useBusinessFeedData } from '../../../context/BusinessFeedContext'
import moment from 'moment'
import MapComponent from '../components/MapComponent';
import { Link } from 'react-router-dom';
import BusinessAdminLayout from '../layout/BusinessAdminLayout';


const LabelValue = ({label,value}) =>{
    return(
        <div className="row my-2">
            <div className="col-md-4 fw-bold">{label}</div>
            <div className="col-md-8">: {value}</div>
            <div className="col-md-12"><hr/></div>
        </div>
    )
}

function BusinessTemplateDetailsPage() {
    let {businessFeedData} = useBusinessFeedData();
    const {template_id} = useParams();

    const [templateDetails,setTemplateDetails] = useState(null)
    const getTemplateDetails = () =>{
        if(businessFeedData!==null){
            let _templateDetails = businessFeedData.templates.business_templates.find(_template => _template.template_id == template_id)
            setTemplateDetails(_templateDetails)
        }
    }


    useEffect(()=>{
        getTemplateDetails();
    },[businessFeedData, template_id])
    return (
        <BusinessAdminLayout>
            {templateDetails &&
                <div className="container">
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <h3 className="ph">Templates</h3>
                        </div>
                        <div className="col-md-6">
                            <Link to={"/"} className="btn btn-primary mx-2 px-5 float-end"> Use</Link>
                            <Link to={"/business/template/form/"+template_id} className="btn btn-primary mx-2 px-5 float-end">Edit</Link>
                        </div>
                        <div className="col-md-12"><hr/></div>
                        <div className="col-md-12">
                            <div className="card">
                                <img src={templateDetails.image!==""?templateDetails.image:"/images/blank-cover.png"} className="event-thumb-image rounded" />
                                <div className="card-body">
                                    <LabelValue label={"Event Title"} value={templateDetails.template_title} />
                                    <LabelValue label={"Start Date"} value={moment(templateDetails.start_date).format("llll")} />
                                    <LabelValue label={"End Date"} value={moment(templateDetails.end_date).format("llll")} />
                                    <LabelValue label={"Price"} value={"$ "+templateDetails.price} />
                                    <LabelValue label={"Age"} value={templateDetails.min_age+" to "+templateDetails.max_age+" years"} />
                                    <LabelValue label={"Mood"} value={templateDetails.type_name} />
                                    <LabelValue label={"Address"} value={templateDetails.address} />
                                    <LabelValue label={"Description"} value={templateDetails.template_description} />
                                    <MapComponent 
                                        lat={templateDetails.latitude}
                                        lng={templateDetails.longitude}
                                        mapHeight={"300px"}
                                        markerId={"ahmedabad"}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12">
                            {/* JSON.stringify(templateDetails) */}
                        </div>

                    </div>
                </div>
            }
        </BusinessAdminLayout>
    )
}

export default BusinessTemplateDetailsPage