import React, { memo } from "react";
import { useWindowSize } from "react-use";
import { useNavigate } from "react-router";
import Button from "../Button";
import s from "./ConfirmCancel.module.scss";

const ConfirmCancel = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <div className={s.container}>
      <div className={s.body}>
        <div className={s.header}>
          <div className={s.icon}>{<i class="bx bx-log-in icon"></i>}</div>
          <span>Confirm Cancel</span>
        </div>
        <div className={s.subHeader}>
          You will be logout, are you sure you want to cancel?
        </div>
        <div className={s.actionBtns}>
          <Button
            className={s.button}
            type={"light"}
            label="Cancel"
            handleClick={() => onClose()}
          />
          <Button
            className={s.button}
            type={"filled"}
            label="Logout"
            handleClick={() => navigate("/auth/logout")}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ConfirmCancel);
