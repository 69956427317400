import React from "react";
import cx from "classnames";
import s from "./Button.module.scss";

const Button = ({
  className,
  type,
  label,
  handleClick,
  Icon,
  btnType,
  isBusiness,
  isLoading,
}) => {
  const onClick = () => {
    if ((handleClick && type !== "disabled") || isLoading) handleClick();
  };

  return (
    <button
      type={btnType}
      className={cx(s.button, className, {
        [s.business]: isBusiness,
        [s.dark]: type === "dark",
        [s.light]: type === "light",
        [s.filled]: type === "filled",
        [s.disabled]: type === "disabled",
      })}
      onClick={() => onClick()}
    >
      {isLoading ? (
        <div className={s.spinner} />
      ) : (
        <>
          {Icon && Icon}
          <span>{label}</span>
        </>
      )}
    </button>
  );
};

export default Button;
