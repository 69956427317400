import React, { memo, useState, useMemo } from "react";
import cx from "classnames";
import { Dialog, DialogContent } from "@mui/material";
import s from "./Home.module.scss";
import ComingSoon from "../../components/ComingSoon";
import PlanetHeader from "../../components/PlanetHeader";
import FooterMap from "../../components/FooterMap";
import LandingPage from "./LandingPage";
import LogIn from "./LogIn";
import Signup from "./Signup";

const Home = () => {
  const [userType, setUserType] = useState(0);
  const [mode, setMode] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const Content = useMemo(() => {
    switch (mode) {
      case 0:
        return (
          <LandingPage
            userType={userType}
            onSelectUserType={(userType) => {
              setUserType(userType);
              if (userType === 1) setIsDialogOpen(true);
            }}
            onSelectMode={(mode) => setMode(mode)}
          />
        );
      case 1:
        return <LogIn />;
      default:
        return <Signup />;
    }
  }, [mode, userType]);

  const handleReset = () => {
    if (mode !== 0) {
      setMode(0);
    } else {
      setUserType(0);
    }
  };

  return (
    <div
      className={cx(s.container, {
        [s.user]: userType === 1,
        [s.business]: userType === 2,
      })}
    >
      <PlanetHeader onClick={() => handleReset()} />
      <div className={cx(s.body, { [s.marginTop]: mode !== 0 })}>
        {Content}
        <FooterMap userType={userType} />
      </div>

      <Dialog
        open={isDialogOpen}
        keepMounted
        onClose={() => setIsDialogOpen(false)}
        maxWidth="lg"
      >
        <DialogContent className={s.dialogContent}>
          <ComingSoon/>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(Home);
