import React, { memo } from "react";
import Button from "../Button";
import s from "./UserSelect.module.scss";

const UserSelect = ({ handleOnSelect }) => {
  return (
    <div className={s.container}>
      <Button
        className={s.button}
        type="light"
        label="user"
        handleClick={() => handleOnSelect(1)}
      />
      <Button
        className={s.button}
        type="light"
        label="business"
        handleClick={() => handleOnSelect(2)}
      />
    </div>
  );
};

export default memo(UserSelect);
