import React, { useState, useEffect } from "react";
import AuthDevieDisplay from "../../auth/components/AuthDevieDisplay";
import AuthHeader from "../../auth/components/AuthHeader";
import { Link } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";
import { useAuth } from "../../../context/AuthContext";
import { useBusinessFeedData } from "../../../context/BusinessFeedContext";
import businessProfileServces from "../businessProfileServces";
import { useAlert } from "../../../context/AlertContext";
import GooglePlaceComponent from "../components/GooglePlaceComponent";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { Dialog, DialogContent } from "@mui/material";
import ConfirmCancel from "../../auth/components/ConfirmCancel";

function BusinessCompanyProfilePage() {
  const { authUser } = useAuth();
  const { AppAlert } = useAlert();
  let { businessFeedData, getBusinessFeedData } = useBusinessFeedData();
  const [formValues, setFormValues] = useState(null);
  const [saving, setSavingStatus] = useState(false);
  const [address, setAddress] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const initialValues = {
    //company_name: "",
    business_name: "",
    abn: "",
    contact_name: "",
    mobile_country: "",
    phone_no: "",
    email: "",
    website: "",
    type_of_industry: "",
    company_description: "",
  };

  useEffect(() => {
    if (businessFeedData) {
      let businessProfile = businessFeedData.business_profile;
      console.log(businessProfile);
      let autoFill = {
        //company_name:businessProfile.company_name,
        business_name: businessProfile.name,
        abn: businessProfile.abn,
        contact_name: businessProfile.contact_name,
        phone_no: businessProfile.phone,
        email: businessProfile.email,
        website: businessProfile.website,
        type_of_industry: businessProfile.type_of_industry,
        company_description: businessProfile.company_description,

        mobile_country: businessProfile.mobile_country,
        mood: businessProfile.mood_id,
        latitude: businessProfile.latitude,
        longitude: businessProfile.longitude,
      };
      setFormValues(autoFill);
      setAddress(businessProfile.address);
    }
  }, [businessFeedData]);

  const validationRule = Yup.object({
    //company_name: Yup.string().required("Required"),
    business_name: Yup.string().required("Required"),
    abn: Yup.string().required("Required"),
    contact_name: Yup.string().required("Required"),
    phone_no: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    website: Yup.string().required("Required"),
    type_of_industry: Yup.string().required("Required"),
  });

  const navigate = useNavigate();
  const onSubmit = (values) => {
    if (address === "") {
      AppAlert("Business Address Required", "error");
      return false;
    } else {
      values.address = address;
    }
    if (latLng !== null) {
      values.latitude = latLng.lat;
      values.longitude = latLng.lng;
    }
    values.user_token = authUser.user_token;
    setSavingStatus(true);
    businessProfileServces
      .updateProfile(values)
      .then((res) => {
        AppAlert(res.data.message);
        getBusinessFeedData();
        navigate("/business/profile/plan");
      })
      .catch((error) => {
        console.log(error);
        AppAlert(error.message, "error");
      })
      .finally(() => {
        setSavingStatus(false);
      });
  };

  return (
    <section class={"login-areas sign-up-consumer pt-5"}>
      <div class="container">
        <div class="login-box">
          <div class="row g-3">
            <div class="col-lg-4 d-flex mx-auto">
              <div class="w-100">
                <AuthHeader />
                <div className="card">
                  <div className="card-body px-4">
                    <p className="bussiness-text">
                      You’re One Step Closer, <br />
                      <span>Our Customers Are Eager to See You!</span>
                    </p>
                    <Formik
                      initialValues={formValues || initialValues}
                      validationSchema={validationRule}
                      onSubmit={onSubmit}
                      enableReinitialize
                      validateOnMount
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-2">
                              <div className="col">
                                {/*
                                                <FormikControl
                                                    control="bsg-input"
                                                    formik={formik}
                                                    type="text"
                                                    placeholder="Company Name"
                                                    label={
                                                        <img
                                                            src="/assets/img/icons/CompanyName.svg"
                                                            alt="Company Name"
                                                        />
                                                    }
                                                    name="company_name"
                                                />
                                                */}
                              </div>
                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="Business Name"
                                  label={
                                    <img
                                      src="/assets/img/icons/BusinessName.svg"
                                      alt="Business Name"
                                    />
                                  }
                                  name="business_name"
                                />
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="ABN/ACN"
                                  label={
                                    <img
                                      src="/assets/img/icons/ACN.svg"
                                      alt="ABN"
                                    />
                                  }
                                  name="abn"
                                />
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="Contact Name"
                                  label={<i class="bx bx-user-circle icon"></i>}
                                  name="contact_name"
                                />
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="Phone Number"
                                  label={
                                    <img
                                      src="/assets/img/icons/PhoneNumber.svg"
                                      alt="Phone Number"
                                    />
                                  }
                                  name="phone_no"
                                />
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="Email"
                                  disabled={true}
                                  label={<i class="bx bx-envelope icon"></i>}
                                  name="email"
                                />
                              </div>

                              <div className="col">
                                {address !== null && (
                                  <GooglePlaceComponent
                                    address={address}
                                    setAddress={setAddress}
                                    latLng={latLng}
                                    setLatLng={setLatLng}
                                  />
                                )}
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="Website"
                                  label={
                                    <img
                                      src="/assets/img/icons/Website.svg"
                                      alt="Email"
                                    />
                                  }
                                  name="website"
                                />
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bsg-input"
                                  formik={formik}
                                  type="text"
                                  placeholder="Type Of Industry"
                                  label={
                                    <img
                                      src="/assets/img/icons/TypeOfIndustry.svg"
                                      alt="Email"
                                    />
                                  }
                                  name="type_of_industry"
                                />
                              </div>

                              <div className="col">
                                <FormikControl
                                  control="bs-textarea"
                                  formik={formik}
                                  type="text"
                                  placeholder="Description Of Company"
                                  label={
                                    <img
                                      src="/assets/img/icons/DescriptionOfCompany.svg"
                                      alt="Email"
                                    />
                                  }
                                  name="company_description"
                                />
                              </div>

                              <div className="col">
                                {saving ? (
                                  <LinearProgress />
                                ) : (
                                  <div className="login-btns mt-2">
                                    {/*
                                                        <Link
                                                            to="/business/profile/plan"
                                                            className="btn app-btn-fade app-btn btn-lg mb-3 text-capitalize"
                                                        >
                                                            Start Trial Now
                                                        </Link>
                                                        */}

                                    <button
                                      className="btn app-btn-fade app-btn btn-lg mb-3 text-capitalize"
                                      type="submit"
                                    >
                                      Start Trial Now
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn pay-btn app-fade-btn"
                                    >
                                      Pay Now
                                    </button>
                                    <button
                                      type="button"
                                      className="btn cancel-btn"
                                      onClick={() => setIsDialogOpen(true)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              {/*JSON.stringify(formik.values) */}
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            {/* <AuthDevieDisplay /> */}
          </div>
        </div>
      </div>
      <Dialog
        className="confirm-cancel-dialog"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        open={isDialogOpen}
        keepMounted
        onClose={() => setIsDialogOpen(false)}
        maxWidth="lg"
      >
        <DialogContent className="confirm-cancel-dialog-content">
          <ConfirmCancel onClose={() => setIsDialogOpen(false)} />
        </DialogContent>
      </Dialog>
    </section>
  );
}

export default BusinessCompanyProfilePage;

/* html


<div className="row row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-2">
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/CompanyName.svg"
                                alt="Company Nameevent-title"
                            />
                            <input
                                placeholder="Company Name"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/BusinessName.svg"
                                alt="Business "
                            />
                            <input
                                placeholder="Business Name"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="form-group">
                            <img src="/assets/img/icons/ACN.svg" alt="ABN/ACN" />
                            <input
                                placeholder="ABN/ACN"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        
                        <div className="col">
                            <div className="form-group">
                            <i class="bx bx-user-circle icon"></i>
                            <input
                                placeholder="Contact Name"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/PhoneNumber.svg"
                                alt="PhoneNumber"
                            />
                            <input
                                placeholder="Phone Number"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <i class="bx bx-envelope icon"></i>
                            <input
                                placeholder="Email Address"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/BusinessAddress.svg"
                                alt="BusinessAddress"
                            />
                            <input
                                placeholder="Business Address"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/Website.svg"
                                alt="Website"
                            />
                            <input
                                placeholder="Website"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/TypeOfIndustry.svg"
                                alt="TypeOfIndustry"
                            />
                            <input
                                placeholder="Type Of Industry"
                                class="form-control"
                                type="text"
                                name=""
                                value=""
                            />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                            <img
                                src="/assets/img/icons/DescriptionOfCompany.svg"
                                alt="DescriptionOfCompany"
                            />
                            <textarea
                                rows="4"
                                className="form-control"
                                placeholder="Description Of Company"
                                name=""
                                value=""
                            ></textarea>
                            </div>
                        </div>
                        <div className="col">
                            <div className="login-btns mt-2">
                            <Link
                                to="/business/profile/plan"
                                className="btn app-btn-fade app-btn btn-lg mb-3 text-capitalize"
                            >
                                Start Trial Now
                            </Link>
                            <button type="button" className="btn app-fade-btn">
                                Pay Now
                            </button>
                            </div>
                        </div>
                        </div>



*/
