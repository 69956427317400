import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/FormikControl/FormikControl";
import {
  Key,
  LanguageOutlined,
  Lock,
  LockOpen,
  LockOutlined,
  MailOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import authServices from "../authServices";
import businessEventServices from "../../business/businessEventServices";
import MapInputComponent from "../../business/components/MapInputComponent";
import ImageInput from "../../../components/ImageInput/ImageInput";
import { useAlert } from "../../../context/AlertContext";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import SelectUserType from "../components/SelectUserType";
import AuthHeader from "../components/AuthHeader";
import withAuthLoginLayout from "../sections/withAuthLoginLayout";
import GooglePlaceComponent from "../../business/components/GooglePlaceComponent";

function BusinessRegisterPage1() {
  const [isBusiness, setIsBusiness] = useState(1);
  const [eventTypes, setEventTypes] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const { AppAlert } = useAlert();
  const { setAuthUser, setIsLoggedIn } = useAuth();
  const [saving, setSavingStatus] = useState(false);
  const navigate = useNavigate();

  const getEventTypes = () => {
    businessEventServices
      .getEventTypes()
      .then((res) => {
        setEventTypes(res.data.event_types);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEventTypes();
  }, []);

  const initialValues = {
    business_name: "",
    email: "",
    password: "",
    mobile_country: "91",
    phone_no: "",
    mood: "",
    address: "",
    is_business: "1",
    device_token: "Web",
  };

  const validationRule = Yup.object({
    business_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    password: Yup.string().required("Required"),
    mobile_country: Yup.string().required("Required"),
    phone_no: Yup.string().required("Required"),
    mood: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    if (imageBase64 !== null) {
      values.logo_image_base64 = imageBase64;
    } else {
      AppAlert("Logo Image is Required", "error");
      return false;
    }
    console.log(latLng);
    if (latLng === null) {
      AppAlert("Please Select your location");
    } else {
      setSavingStatus(true);
      values.latitude = latLng.lat;
      values.longitude = latLng.lng;
      console.log(values);

      authServices
        .registerBusiness(values)
        .then((res) => {
          //console.log(res);
          if (res.data.user !== undefined) {
            setAuthUser(res.data.user);
            setIsLoggedIn(true);
            navigate("/");
          }
        })
        .catch((error) => {
          AppAlert(error.message, "error");
          //console.log(error);
        })
        .finally(() => {
          setSavingStatus(false);
        });
    }

    /*authServices.attemptLogin(values).then(res=>{
            console.log(res);
        }).catch(error=>{
            console.log(error);
        })
        */
  };

  return (
    <div className="card">
      <div className="card-body">
        <h1 class="card-title mt-4">SIGN UP</h1>
        <p class="card-text">You’re on the right path.</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationRule}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnMount
        >
          {(formik) => {
            //console.log(formik);
            return (
              <Form>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="text-center">
                      <ImageInput
                        label={"Business Logo"}
                        value={null}
                        setImageBase64={setImageBase64}
                        type="circle"
                      />
                    </div>
                  </div>
                  <div class="login-tabs">
                    <SelectUserType
                      isBusiness={isBusiness}
                      setIsBusiness={setIsBusiness}
                    />
                  </div>
                  <div className="mb-3"></div>
                  <div className="col-md-6 mb-3">
                    <FormikControl
                      control="mui-input"
                      formik={formik}
                      type="text"
                      label={"Business Name"}
                      name="business_name"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <FormikControl
                      control="mui-input"
                      formik={formik}
                      type="text"
                      label={"Email"}
                      name="email"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <FormikControl
                      control="mui-input"
                      formik={formik}
                      type="password"
                      label={"Password"}
                      name="password"
                    />
                  </div>

                  <div className="col-md-6 mb-3 px-4">
                    <div className="row">
                      <div className="col-4 px-0">
                        <FormikControl
                          control="mui-input"
                          formik={formik}
                          type="text"
                          label={"Country Code"}
                          name="mobile_country"
                        />
                      </div>
                      <div className="col-8 px-0">
                        <FormikControl
                          control="mui-input"
                          formik={formik}
                          type="text"
                          label={"Phone no"}
                          name="phone_no"
                        />
                      </div>
                    </div>
                  </div>
                  {/*
                                                <div className="col-md-6 mb-3">
                                                    <div className="border pa-1 mx-0 rounded">
                                                        <div className="row">
                                                            <div className="col-12">Phone number</div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4 ps-3">
                                                                <FormikControl control="input-box" formik={formik} type="text"
                                                                    label={"Code"} name="mobile_country" 
                                                                />
                                                            </div>
                                                            <div className="col-8 pe-3">
                                                                <FormikControl control="input-box" formik={formik} type="text"
                                                                    label={"Phone"} name="phone_no"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                */}

                  {eventTypes && (
                    <div className="col-md-6 mb-3">
                      <FormikControl
                        control="bs-select-lg"
                        formik={formik}
                        options={eventTypes}
                        valueField="event_type_id"
                        labelField="type_name"
                        label={"Mood"}
                        name="mood"
                      />
                    </div>
                  )}

                  <div className="col-md-6 mb-3">
                    <FormikControl
                      control="mui-input"
                      formik={formik}
                      type="text"
                      label={"Website"}
                      name="website"
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <FormikControl
                      control="bs-textarea"
                      formik={formik}
                      label={"Address"}
                      name="address"
                    />
                  </div>

                  <div className="col-md-12">
                    <MapInputComponent
                      lat={"19.00461"}
                      lng={"73.04871"}
                      mapHeight={"300px"}
                      markerId={"ahmedabad"}
                      setLatLng={setLatLng}
                    />
                  </div>
                  <div className="col-md-12">
                    {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */}
                    <div className="row">
                      <button
                        disabled={!formik.isValid}
                        type="submit"
                        className="btn btn-primary mt-4 float-end btn-lg"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 my-4 text-center">
                    <small>
                      Already have an account ?{" "}
                      <Link to="/auth/login" className="fw-bold text-dark">
                        Login
                      </Link>{" "}
                    </small>
                  </div>
                  <div className="mb-5"></div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

function BusinessRegisterPage() {
  const [isBusiness, setIsBusiness] = useState(1);
  const [eventTypes, setEventTypes] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const { AppAlert } = useAlert();
  const { setAuthUser, setIsLoggedIn } = useAuth();
  const [saving, setSavingStatus] = useState(false);
  const navigate = useNavigate();
  const [latLng, setLatLng] = useState(null);
  const [address, setAddress] = useState("");

  const getEventTypes = () => {
    businessEventServices
      .getEventTypes()
      .then((res) => {
        setEventTypes(res.data.event_types);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEventTypes();
  }, []);

  const initialValues = {
    business_name: "",
    email: "",
    password: "",
    mobile_country: "91",
    phone_no: "",
    mood: "",
    //address: "",
    is_business: isBusiness,
    device_token: "Web",
  };

  const validationRule = Yup.object({
    business_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    password: Yup.string().required("Required"),
    mobile_country: Yup.string().required("Required"),
    phone_no: Yup.string().required("Required"),
    mood: Yup.string().required("Required"),
    //address: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    if (isBusiness == "") {
      AppAlert("User Module Comming Soon", "error");
      return false;
    }
    if (address === "") {
      AppAlert("Event Address Required", "error");
      return false;
    } else {
      values.address = address;
    }
    if (imageBase64 !== null) {
      values.logo_image_base64 = imageBase64;
    } else {
      AppAlert("Logo Image is Required", "error");
      return false;
    }
    console.log(latLng);
    if (false) {
      //(latLng === null) {
      AppAlert("Please Select your location");
    } else {
      setSavingStatus(true);
      values.latitude = "19.00461"; //latLng.lat;
      values.longitude = "73.04871"; //latLng.lng;
      console.log(values);

      authServices
        .registerBusiness(values)
        .then((res) => {
          //console.log(res);
          if (res.data.user !== undefined) {
            setAuthUser(res.data.user);
            setIsLoggedIn(true);
            navigate("/");
          }
        })
        .catch((error) => {
          AppAlert(error.message, "error");
          //console.log(error);
        })
        .finally(() => {
          setSavingStatus(false);
        });
    }

    /*authServices.attemptLogin(values).then(res=>{
            console.log(res);
        }).catch(error=>{
            console.log(error);
        })
        */
  };

  return (
    <div className="card">
      <div className="card-body">
        <h1 class="card-title mt-4">SIGN UP</h1>
        <p class="card-text">You’re on the right path.</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationRule}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnMount
        >
          {(formik) => {
            //console.log(formik);
            return (
              <Form>
                <div className="sign-up-profile">
                  <ImageInput
                    label={"Business Logo"}
                    value={null}
                    setImageBase64={setImageBase64}
                    type="circle"
                  />
                </div>
                <div class="login-tabs">
                  <SelectUserType
                    isBusiness={isBusiness}
                    setIsBusiness={setIsBusiness}
                  />
                </div>
                <div className="row row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1 g-2">
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-user-circle"></i>}
                      name="business_name"
                      placeHolder="Business Name"
                    />
                  </div>

                  {eventTypes && (
                    <div className="col">
                      <FormikControl
                        control="bsg-select"
                        formik={formik}
                        options={eventTypes}
                        valueField="event_type_id"
                        labelField="type_name"
                        label={
                          <img
                            src="/assets/img/icons/mood.svg"
                            alt="event-mood"
                          />
                        }
                        name="mood"
                        placeHolder="Email"
                      />
                    </div>
                  )}

                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-envelope"></i>}
                      name="email"
                      placeHolder="Email"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="password"
                      label={<i class="bx icon bx-lock"></i>}
                      name="password"
                      placeHolder="Password"
                    />
                  </div>

                  <div className="col">
                    {/*
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      label={<i class="bx icon bx-map-pin"></i>}
                      name="address"
                      placeHolder="Address"
                    />
                    */}
                    {address !== null && (
                      <GooglePlaceComponent
                        address={address}
                        setAddress={setAddress}
                        latLng={latLng}
                        setLatLng={setLatLng}
                      />
                    )}
                  </div>

                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-phone"></i>}
                      name="phone_no"
                      placeHolder="Phone"
                    />
                  </div>
                  {/*
                                                <div className="col-md-12 mb-3 px-4">
                                                    <div className="row">
                                                        <div className="col-4 px-0">
                                                            <FormikControl control="mui-input" formik={formik} type="text"
                                                                label={"Country Code"} name="mobile_country"
                                                            />
                                                        </div>
                                                        <div className="col-8 px-0">
                                                            <FormikControl control="mui-input" formik={formik} type="text"
                                                                label={"Phone no"} name="phone_no"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                */}
                  {/*
                                                <div className="col-md-6 mb-3">
                                                    <div className="border pa-1 mx-0 rounded">
                                                        <div className="row">
                                                            <div className="col-12">Phone number</div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4 ps-3">
                                                                <FormikControl control="input-box" formik={formik} type="text"
                                                                    label={"Code"} name="mobile_country" 
                                                                />
                                                            </div>
                                                            <div className="col-8 pe-3">
                                                                <FormikControl control="input-box" formik={formik} type="text"
                                                                    label={"Phone"} name="phone_no"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                */}

                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx icon bx-globe"></i>}
                      name="website"
                      placeHolder="Website"
                    />
                  </div>

                  {/*
                                                <div className="col-md-12">
                                                    
                                                            <MapInputComponent 
                                                                lat={"19.00461"}
                                                                lng={"73.04871"}
                                                                mapHeight={"300px"}
                                                                markerId={"ahmedabad"}
                                                                setLatLng={setLatLng}
                                                            />
                                                </div>
                                                */}
                  <div className="col">
                    {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */}
                    <div className="form-group">
                      <button
                        disabled={!formik.isValid}
                        type="submit"
                        className="btn btn-primary app-btn-fade"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-12 my-4 text-center">
                    <small>
                      Already have an account ?{" "}
                      <Link to="/auth/login" className="fw-bold text-dark">
                        Login
                      </Link>{" "}
                    </small>
                  </div> */}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default withAuthLoginLayout(BusinessRegisterPage);
