import React, { memo, useMemo, useState, useEffect } from "react";
import cx from "classnames";
import s from "./AnimatedBanner.module.scss";

const AnimatedBanner = ({ userType }) => {
  const [isTransition, setIsTransition] = useState(true);
  const [isUnmounted, setIsUnmounted] = useState(true);
  const [prevType, setPrevType] = useState(userType);

  useEffect(() => {
    setPrevType(userType);

    const isSame = userType === 1 && prevType === 0;
    const isSame2 = userType === 0 && prevType === 1;

    if (isSame || isSame2) {
      return;
    }

    setIsTransition(true);
    setTimeout(() => {
      setIsUnmounted(true);
    }, 200);

    setTimeout(() => {
      setIsTransition(false);
      setIsUnmounted(false);
    }, 400);
  }, [userType]);

  const DEVICES = useMemo(() => {
    if (userType === 2)
      return (
        <>
          <div className={s.tablet}>
            <img alt="tablet" src="/tablet.png" />
          </div>
          <div className={s.phone3}>
            <img alt="business-phone" src="/business-phone.png" />
          </div>
        </>
      );
    else
      return (
        <>
          <div className={s.phone1}>
            <img alt="phone1" src="/phone1.png" />
          </div>
          <div className={s.phone2}>
            <img alt="phone2" src="/phone2.png" />
          </div>
        </>
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnmounted]);

  return (
    <div className={cx(s.container, { [s.show]: !isTransition })}>
      {!isUnmounted && (
        <>
          {DEVICES}
          <div className={s.particle1}>
            <img alt="particle1" src="/particle1.png" />
          </div>
          <div className={s.particle2}>
            <img alt="particle2" src="/particle1.png" />
          </div>
          <div className={s.particle3}>
            <div className={s.body} />
          </div>
          <div className={s.particle4}>
            <div className={s.body} />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(AnimatedBanner);
