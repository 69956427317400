import React, { useState, memo } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import s from "./Login.module.scss";
import FormikControl from "../../../../../components/FormikControl/FormikControl";
import authServices from "../../../authServices";
import { useAuth } from "../../../../../context/AuthContext";
import { useAlert } from "../../../../../context/AlertContext";
import Button from "../../../components/Button";

const Login = () => {
  const { setAuthUser, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { AppAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    username: "", //"arvindb1@gmail.com",
    password: "", //"arvind",
    remember: true,
    device_token: "Web",
  };

  const validationRule = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const submitLogin = (values) => {
    setIsLoading(true);
    values.is_business = true;
    authServices
      .attemptLogin(values)
      .then((res) => {
        if (res.data.user !== undefined) {
          setAuthUser(res.data.user);
          setIsLoggedIn(true);
          navigate("/");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        AppAlert(error.message, "error");
        setIsLoading(false);
      });
  };

  return (
    <div className={s.container}>
      <div className={s.form}>
        <div className={s.header}>
          <span className={s.title}>LOG IN</span>
          <span className={s.subHeader}>Welcome Back!</span>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationRule}
          onSubmit={submitLogin}
          enableReinitialize
          validateOnMount
        >
          {(formik) => {
            //console.log(formik);
            return (
              <Form>
                <div className={s.inputs}>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="text"
                      label={<i class="bx bx-envelope icon"></i>}
                      name="username"
                      placeholder="Email"
                    />
                  </div>
                  <div className="col">
                    <FormikControl
                      control="bsg-input"
                      formik={formik}
                      type="password"
                      label={<i class="bx bx-lock icon"></i>}
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="col">
                    <div className={s.actionBtns}>
                      <Button
                        type={
                          formik.isValid && !isLoading ? "filled" : "disabled"
                        }
                        isLoading={isLoading}
                        label="Log In"
                        btnType="submit"
                      />
                      <Link className={s.link} to="/auth/forgot-password">
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default memo(Login);
